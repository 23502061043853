import React, { ReactElement, useEffect, useState } from "react"
import { graphql } from "gatsby"
import Link from "../../components/ui/Link"
import ContentLayout from "@Src/components/sitestructure/ContentLayout"
import SEO from "@Src/components/SEO"
import { useApp, usePageTheme } from "@Src/context/AppContext"
import ContentfulModule from "@Src/components/ContentfulModule"
import { IBlock } from "@Src/types/ContentfulTypes"
import Text from "@Src/components/Text"
import moment from "moment"
import * as styles from './index.module.scss';
import Container from "@Src/components/sitestructure/Container"

const IndexPage = ({
  data: {
    allContentfulPageTypeArticle: { edges },
  },
  location,
  pageContext,
  transitionStatus
}) => {
  const title = '', seoSettings = 'label pitch', content = ''
  const { setTheme: setPageTheme } = usePageTheme();
  
  const { mode, pageType, setPageType, setPageTitle } = useApp();
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  
  console.log(edges)

  useEffect(() => {
      if (pageType === 'index' && transitionStatus == 'exiting') {
          setLocalTransStatus(transitionStatus + 'Index');
      } else {
          setLocalTransStatus(transitionStatus);
      }

      if (transitionStatus == 'entering' || transitionStatus == 'entered') {
          setPageTheme('light');
          setPageTitle(title);
          setPageType(pageContext.type);
      }
  }, [transitionStatus]);

  useEffect(() => {
      if (transitionStatus == 'entered') {
          setPageTheme('light');
      }
  }, [mode]);

  return (
    <ContentLayout transitionState={localTransStatus}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>  
        <div style={{padding: 0}}>
        <Container>
          <Text heading01={`Articles`} />
        </Container>
        <Container>
          <div className={styles.articleList}>
            {edges.map((edge : any) => edge.node).map((article : any) => {
              return (
                <Link id={article.slug} url={`/${article.slug}`}>
                  <article key={article.slug}> 
                    <img src={article?.image?.url ?? `/assets/svg/ALOADED_Placeholder_Image.svg`} style={{aspectRatio: '16/9', width: '100%'}} />
                    <div style={{padding: 13}}>
                      <Text theme={'light'} heading03={article.title} />
                      <Text theme={'light'} htmlText={article.published ? moment(article.published).fromNow() : 'Unknown date'} />
                      <Text theme={'light'} heading01={article.summary} />
                    </div>
                  </article>
                </Link>
              )
            })}
          </div>
        </Container>
      </div>
		</ContentLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPageTypeArticle(filter: { node_locale: { eq: "en-US" }}, sort: { fields: [published], order: DESC }) {
      edges {
        node {
          image {
            url
          }
          published
          ... on IPageNode {
            ...PageFields
            content {
              ... on IBlockNode {
                ...AllBlocks
              }
            }
          }
        }
      } 
    }
  }
`